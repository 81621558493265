.small-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.small-cont {
  flex-basis: calc(50% - 10px);
  /* border: 1px solid #ccc; */
  padding: 10px;
  border-radius: 4px;
}
.second-box {
  border: 2px solid #3390ff;
  width: 40%;
  height: auto;
  border-radius: 10px;
  padding: 40px;
}
.second-box img {
  background-color: #3fc041;
  padding: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .whole-section {
    display: flex;
    flex-direction: column;
  }
  .first-box {
    padding: 10px;
  }
  .second-box {
    border: 2px solid #3390ff;
    width: 90%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    padding: 40px;
    margin-bottom: 50px;
  }
  .text-area1 {
    width: 90%;
  }
}
