@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.whitespace-nowrap {
    white-space: nowrap;
   }
   
