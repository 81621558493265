body {
  margin: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .first_cards {
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
  }

  .second_cards {
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (min-width: 767px) {
  .first_cards {
    display: inline-flex;
    /* justify-content: space-between; */
  }

  .first_cards>div {
    margin: 50px;
  }

  .second_cards>div {
    margin: 50px;
  }

  .second_cards {
    display: inline-flex;
    /* justify-content: space-around; */
  }
}