/* General Container */

.app-promo {
  display: flex;
  justify-content: center;
  gap: 150px;
  border: 2px solid #3390ff;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 10px;
  padding: 40px;
  /* height: 474px; */
}

/* Left Section: Content */
.app-promo-content {
  flex: 1;
  max-width: 50%;
  text-align: left;
  padding: 20px;
}
.highlight {
  font-weight: 700;
  font-size: 40px;
}
.highlight1 {
  font-weight: 700;
  font-size: 40px;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  .app-promo {
    display: flex;
    flex-direction: column-reverse;
    border: none;
    margin: 0;
    justify-content: center;
    padding: 0;
    gap: 40px;
    margin-left: 10%;
  }
  .app-promo-content {
    /* flex: 1; */
    max-width: 100%;
  }
  .highlight {
    font-weight: 700;
    font-size: 40px;
  }
  .highlight1 {
    font-weight: 700;
    font-size: 40px;
  }
}
