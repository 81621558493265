/* General styles */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Add a border to the form group */
  padding: 20px; /* Add some padding inside the form group */
  max-width: 600px; /* Set a max width for the form group */
  margin: 0 auto; /* Center the form group */
  background-color: #f3fff1;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(130, 129, 129, 0.622);
  font-size: 1.2rem;
}
.pincodelabel ::placeholder {
  color: #666666;
  font-weight: 800;
  font-size: 1rem;
}
.regheading {
  font-size: 3rem;
  color: #04801e;
  font-weight: bold;
  padding: 10px;
}
.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #ffffff;
}
.dealerfarmer TextField {
  width: 50%;
}
.dealerlabel {
  padding-bottom: 5px;
}
.form-group button {
  background-color: #05ab2a;
  color: #f3fff1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-group button:hover {
  background-color: #04801e;
}

/* Responsive styles for desktop */
@media (min-width: 1024px) {
  .form-group {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  .form-group label {
    flex: 1;
    text-align: right;
    margin-right: 10px;
    margin-bottom: 0;
    display: flex;
    /* Remove bottom margin for desktop */
  }
  .whatsappdiv {
    padding: 0;
    margin: 0;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    flex: 2;
  }

  .form-group button {
    flex: 1;
    margin-left: 10px;
  }
}

/* Responsive styles for tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .form-group {
    flex-direction: row;
    flex-wrap: wrap; /* Allow the items to wrap if necessary */
    gap: 20px;
  }

  .form-group label {
    flex: 1;
    text-align: right;
    margin-right: 10px;
    margin-bottom: 0; /* Remove bottom margin for tablet */
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    flex: 2;
  }

  .form-group button {
    flex: 1;
    margin-left: 10px;
  }
}

/* Responsive styles for mobile */
@media (max-width: 767px) {
  .form-group {
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
  }
  .regheading {
    font-size: 2rem;
    margin-top: 60px;
  }
  .form-group label {
    text-align: left;
    margin-bottom: 5px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
  }

  .form-group button {
    width: 100%;
  }
}
