body {
  margin: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .container {
    margin-left: -140px;
    width: 150px;
    height: 300px;
    margin-top: -50px;
  }
  .image_top {
    margin-left: 200px;
    margin-top: 150px;
  }

  .image_left {
    margin-left: 300px;
    margin-top: -250px;
  }

  .image_right {
    margin-left: 370px;
    margin-top: -20px;
  }
  .bigblue {
    z-index: -10;
    margin-left: 150px;
    margin-top: -150px;
  }
  .smallblue {
    margin-left: 360px;
    margin-top: 100px;
  }
  .smallgreen {
    margin-left: 420px;
    margin-top: -170px;
  }
  .cul {
    padding-top: 50px;
    margin: auto;
  }
  .culti{
    margin-left: 5px;
  }
  .cultivate{
    padding: 20px;
  }
}

@media screen and (min-width: 767px) {
  
  .image_top {
    margin-left: 200px;
    margin-top: 150px;
  }

  .image_left {
    margin-left: 300px;
    margin-top: -400px;
  }

  .image_right {
    margin-left: 410px;
    margin-top: -20px;
  }
  .bigblue {
    z-index: -10;
    margin-left: 150px;
    margin-top: -200px;
  }
  .smallblue {
    margin-left: 400px;
    margin-top: 220px;
  }
  .smallgreen {
    margin-left: 550px;
    margin-top: -250px;
  }
  .cul {
    padding-top: 50px;
    margin-left: 100px;
  }
}
